export const imgixBaseUrl = 'https://digsconnect.imgix.net';

export const imgixNsfasLogoUrl = `${imgixBaseUrl}/placeholder_verifications/NSFAS-master-logo.png`;
export const imgixBedUrl = `${imgixBaseUrl}/feature_icons/feature/bed.svg`;
export const imgixBathUrl = `${imgixBaseUrl}/feature_icons/feature/bath.svg`;
export const imgixVarsityCollegeLogoUrl = `${imgixBaseUrl}/static/media/varsity_college_logo.png`;
export const imgixAIELogoUrl = `${imgixBaseUrl}/static/media/aie_logo.png`;
export const imgixSneakerUrlDark = `${imgixBaseUrl}/static/media/sneaker_dark.png`;
export const imgixSneakerUrl = `${imgixBaseUrl}/static/media/dance.png`;
export const imgixHousesUrl = `${imgixBaseUrl}/static/media/houses.png`;
export const imgixLandlordPicUrl = `${imgixBaseUrl}/static/media/landlord_banner.png`;
export const imgixFallback = `${imgixBaseUrl}/assets/placeholder.svg`;
export const imgixLandlordLandingUrl = `${imgixBaseUrl}/static/media/landlord.png`;
export const imgixMsdfUrl = `${imgixBaseUrl}/static/media/msdf.png`;
export const imgixManageDigsUrl = `${imgixBaseUrl}/static/manage-digs-img.png`;
