import client from '../utils/fetch-client';

const path = '/auth';
const pathV2 = '/v2/auth';

export default {
	refreshToken: () => {
		return client.post(`${pathV2}/token/refresh`);
	},

	login: ({ username, password } = {}) => {
		return client.post(`${pathV2}/token`, {
			body: {
				username,
				password,
			},
		});
	},

	logout: () => {
		return client.post(`${pathV2}/token/logout`);
	},

	facebook: ({ token } = {}) => {
		return client.post(`${path}/facebook`, {
			body: {
				access_token: token,
			},
		});
	},

	google: ({ token } = {}) => {
		return client.post(`${path}/google`, {
			body: {
				access_token: token,
			},
		});
	},

	googleV2: ({ token } = {}) => {
		return client.post(`${pathV2}/google`, {
			body: {
				access_token: token,
			},
		});
	},

	registerUser: ({ email, password1, password2, recaptcha_response } = {}) => {
		return client.post(`${path}/registration`, {
			body: {
				email,
				password1,
				password2,
				recaptcha_response,
			},
		});
	},

	registerAnonymousUser: (email, firstName, lastName, recaptchaToken, source = 'nosignup') => {
		return client.post(`${path}/registration`, {
			body: {
				email,
				first_name: firstName,
				last_name: lastName,
				source: source,
				recaptcha_response: recaptchaToken,
			},
		});
	},

	oneTimeLogin: ({ otl } = {}) => {
		return client.post(`${path}/token/otl/${otl}`, {}, false);
	},

	resetPassword(email) {
		return client.post(`${path}/password/reset`, {
			body: {
				email: email,
			},
		});
	},
};
