import React, { useState } from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { InboxType } from './types';
import {
	Conversation,
	ConversationAttachments,
	ConversationBookings,
	ConversationStatus,
} from 'models/inbox';
import { ReactComponent as PhoneSvg } from 'assets/icons/phone.svg';
import { ReactComponent as MinusSvg } from 'assets/icons/minus.svg';
import { ReactComponent as PlusSvg } from 'assets/icons/plus.svg';
import { ReactComponent as DocumentSvg } from 'assets/icons/document.svg';
import { ReactComponent as PersonSvg } from 'assets/icons/person.svg';
import { ReactComponent as OccupationSvg } from 'assets/icons/occupation.svg';
import { ReactComponent as StudySvg } from 'assets/icons/study.svg';
import { ReactComponent as TickSvg } from 'assets/icons/tick.svg';
import { ReactComponent as CrossSvg } from 'assets/icons/cross.svg';
import { ReactComponent as WhatsAppOutlineSvg } from 'assets/icons/whatsapp_outline.svg';
import { ReactComponent as PointerTriangleSvg } from 'assets/images/invoicing/pointer-triangle.svg';
import Button from 'lib/Button';
import Divider from 'lib/Divider';
import routes from 'config/routes';
import { Property } from 'models/property';
import numberFormatService from 'services/numberFormatService';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';

const { colors } = theme;

interface RightPanelProps {
	bookings?: ConversationBookings;
	chat: Conversation;
	attachments?: ConversationAttachments;
	inboxType: InboxType;
	propertyDictionary: { [uuid: string]: Property };
	onCancel(): void;
	onConfirm(): void;
}

enum Tab {
	Details = 'Details',
	Attachments = 'Attachments',
}

const RightPanel = ({
	bookings,
	attachments,
	inboxType,
	propertyDictionary,
	chat,
	onCancel,
	onConfirm,
}: RightPanelProps) => {
	const tabs = [Tab.Details, Tab.Attachments];
	const [activeTab, setActiveTab] = useState(Tab.Details);
	const [showContact, setShowContact] = useState(false);
	const [showLease, setShowLease] = useState(false);
	const [showPersonal, setShowPersonal] = useState(false);
	const [showEducational, setShowEducational] = useState(false);
	const [showOccupation, setShowOccupation] = useState(false);
	const [showDiscountNotice, setShowDiscountNotice] = useState(true);

	const getFileName = (fileUrl: string) => {
		const parts = fileUrl.split('/');
		const part = parts[parts.length - 1];
		return part.split('?')[0];
	};

	const getStatusColor = (status: ConversationStatus) => {
		if (status === ConversationStatus.Pending) {
			return colors.orange;
		}
		if (status === ConversationStatus.Connected) {
			return colors.darkTurquoise;
		}
		if (status === ConversationStatus.Confirmed) {
			return colors.brightBlue;
		}
		if (status === ConversationStatus.Cancelled) {
			return colors.grey60;
		}
		if (status === ConversationStatus.Expired) {
			return colors.grey60;
		}
		return colors.darkBlue;
	};

	return (
		<>
			<HeaderBar>
				{tabs.map((tab: Tab) => (
					<HeaderItem
						key={tab}
						active={activeTab === tab}
						onClick={() =>
							setActiveTab(prev => (prev === Tab.Details ? Tab.Attachments : Tab.Details))
						}
					>
						{tab}
					</HeaderItem>
				))}
			</HeaderBar>
			{activeTab === Tab.Details && bookings && (
				<>
					<PanelCard>
						<DocumentsHeader>
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-start',
									alignItems: 'center',
									gap: 8,
								}}
							>
								<ProfilePicture src={bookings.other_user.profile_picture.url} />
								<div>
									<NameDisplay>
										{bookings.other_user.first_name} {bookings.other_user.last_name}
									</NameDisplay>
									<StatusDisplay style={{ color: getStatusColor(bookings.status) }}>
										{bookings.status_readable}
									</StatusDisplay>
								</div>
							</div>
							{bookings.other_user && (
								<PhoneSvg
									style={{ cursor: 'pointer' }}
									onClick={() => setShowContact(prev => !prev)}
								/>
							)}
						</DocumentsHeader>
						<DefaultText>Enquiring to</DefaultText>
						{bookings.listings.map(listing => (
							<a
								style={{ color: colors.darkBlue }}
								key={listing.share_url}
								href={listing.share_url}
								target="_blank"
							>
								<DefaultText large>
									<u>Listing</u>
								</DefaultText>
							</a>
						))}

						<DefaultText style={{ marginTop: 16 }}>Requested move-in date</DefaultText>
						<DefaultText large>{bookings.lease_information.occupancy_start_date}</DefaultText>

						<DefaultText style={{ marginTop: 16 }}>Requested lease length</DefaultText>
						<DefaultText large>{bookings.lease_information.occupancy_lease_term}</DefaultText>

						{bookings.personal_information?.payment_provider && (
							<>
								<DefaultText style={{ marginTop: 16 }}>Fee payer</DefaultText>
								<DefaultText large>{bookings.personal_information.payment_provider}</DefaultText>
							</>
						)}

						{bookings.booking_information?.room_uuid && (
							<>
								<DefaultText style={{ marginTop: 16 }}>Room type</DefaultText>
								<DefaultText large>
									{
										(
											propertyDictionary[bookings.booking_information.property_uuid]?.rooms || []
										)?.find(({ uuid }) => uuid === bookings.booking_information.room_uuid)?.title
									}
								</DefaultText>
							</>
						)}

						{inboxType === InboxType.Landlord && bookings.status === ConversationStatus.Confirmed && (
							<div style={{ marginTop: 16, position: 'relative' }}>
								<Button isOutline isFullWidth link={routes.manage_listing_invoices}>
									<ButtonContentContainer>
										<DocumentSvg style={{ width: 16 }} />
										<ButtonText>View invoice</ButtonText>
									</ButtonContentContainer>
								</Button>
							</div>
						)}
						{inboxType === InboxType.Landlord && bookings.status === ConversationStatus.Connected && (
							<>
								<div style={{ marginTop: 16, position: 'relative' }}>
									{showDiscountNotice && (
										<DiscountNotice>
											<div>
												Click the button below to confirm this placement! You will receive 15% off
												DigsConnect fees.
											</div>
											<CrossSvg
												onClick={() => setShowDiscountNotice(false)}
												fill={colors.white}
												width={24}
												style={{ cursor: 'pointer' }}
											/>
											<TourPointer />
										</DiscountNotice>
									)}
									<Button isOutline isFullWidth onClick={onConfirm}>
										<ButtonContentContainer>
											<TickSvg style={{ stroke: colors.darkBlue }} />
											<ButtonText>Confirm placement</ButtonText>
										</ButtonContentContainer>
									</Button>
								</div>
								<div style={{ marginTop: 16 }}>
									<Button isOutline isFullWidth onClick={onCancel}>
										<ButtonContentContainer>
											<CrossSvg style={{ width: 8 }} />
											<ButtonText>Cancel enquiry</ButtonText>
										</ButtonContentContainer>
									</Button>
								</div>
							</>
						)}
						{inboxType === InboxType.Landlord && bookings.status === ConversationStatus.Cancelled && (
							<>
								<div style={{ marginTop: 16 }}>
									<Button isOutline isFullWidth onClick={onConfirm}>
										<ButtonContentContainer>
											<TickSvg style={{ stroke: colors.darkBlue }} />
											<ButtonText>Confirm placement</ButtonText>
										</ButtonContentContainer>
									</Button>
								</div>
							</>
						)}

						{inboxType === InboxType.Tenant &&
							(bookings.status === ConversationStatus.Pending ||
								bookings.status === ConversationStatus.Connected) && (
								<div style={{ marginTop: 16 }}>
									<Button isOutline isFullWidth onClick={onCancel}>
										<ButtonContentContainer>
											<CrossSvg style={{ width: 8 }} />
											<ButtonText>Cancel enquiry</ButtonText>
										</ButtonContentContainer>
									</Button>
								</div>
							)}
					</PanelCard>

					{bookings.other_user &&
						(Boolean(bookings.other_user.cellphone) || Boolean(bookings.other_user.email)) && (
							<PanelCard>
								<Row style={{ justifyContent: 'space-between' }}>
									<Row style={{ gap: 8 }}>
										<PhoneSvg />
										<DefaultText>
											<b>Contact information</b>
										</DefaultText>
									</Row>
									{showContact ? (
										<MinusSvg
											style={{ cursor: 'pointer' }}
											onClick={() => setShowContact(prev => !prev)}
										/>
									) : (
										<PlusSvg
											style={{ cursor: 'pointer' }}
											onClick={() => setShowContact(prev => !prev)}
										/>
									)}
								</Row>

								{showContact && (
									<>
										{Boolean(bookings.other_user.cellphone) && (
											<SpacedRow>
												<div>
													<DefaultText style={{ marginTop: 16 }}>Mobile number</DefaultText>
													{inboxType === InboxType.Tenant &&
													bookings.other_user.first_name?.toLowerCase() === 'concierge' ? (
														<DefaultText large>-</DefaultText>
													) : (
														<a
															style={{ color: colors.darkBlue }}
															href={`tel:${bookings.other_user.cellphone}`}
														>
															<DefaultText large>{bookings.other_user.cellphone}</DefaultText>
														</a>
													)}
												</div>
												{inboxType === InboxType.Landlord && (
													<a
														href={`whatsapp://send?phone=${bookings.other_user.cellphone}&text=Thank you for your interest in my property on DigsConnect. Would you like to arrange a viewing?`}
													>
														<WhatsAppOutlineSvg
															style={{ fill: colors.green, width: 40, height: 40 }}
														/>
													</a>
												)}
											</SpacedRow>
										)}

										{Boolean(bookings.other_user.email) && (
											<>
												<DefaultText style={{ marginTop: 16 }}>Email address</DefaultText>
												{inboxType === InboxType.Tenant &&
												bookings.other_user.first_name?.toLowerCase() === 'concierge' ? (
													<DefaultText large>-</DefaultText>
												) : (
													<a
														style={{ color: colors.darkBlue }}
														href={`mailto:${bookings.other_user.email}`}
													>
														<DefaultText large>{bookings.other_user.email}</DefaultText>
													</a>
												)}
											</>
										)}
									</>
								)}
							</PanelCard>
						)}

					{bookings.lease_information && Boolean(bookings.lease_information.occupant_rental) && (
						<PanelCard>
							<Row style={{ justifyContent: 'space-between' }}>
								<Row style={{ gap: 8 }}>
									<DocumentSvg style={{ width: 16 }} />
									<DefaultText>
										<b>Lease information</b>
									</DefaultText>
								</Row>
								{showLease ? (
									<MinusSvg
										style={{ cursor: 'pointer' }}
										onClick={() => setShowLease(prev => !prev)}
									/>
								) : (
									<PlusSvg
										style={{ cursor: 'pointer' }}
										onClick={() => setShowLease(prev => !prev)}
									/>
								)}
							</Row>

							{showLease && Boolean(bookings.lease_information.occupant_rental) && (
								<>
									<DefaultText style={{ marginTop: 16 }}>Rental amount</DefaultText>
									<DefaultText large>
										{numberFormatService.formatCurrency(
											bookings.lease_information.occupant_rental,
											propertyDictionary[bookings.booking_information?.property_uuid]?.currency
												.currency_symbol,
										)}
									</DefaultText>
									{inboxType === InboxType.Landlord && (
										<>
											<DefaultText style={{ marginTop: 16 }}>Total lease value</DefaultText>
											<DefaultText large>
												{numberFormatService.formatCurrency(
													bookings.lease_information.occupant_rental *
														+bookings.lease_information.occupancy_lease_term.split(' ')[0],
													propertyDictionary[bookings.booking_information?.property_uuid]?.currency
														.currency_symbol,
												)}
											</DefaultText>
											<DefaultText style={{ marginTop: 16 }}>
												Approximate 3.5% DigsConnect Fee (excl. VAT)
											</DefaultText>
											<DefaultText large>
												{numberFormatService.formatCurrency(
													+(
														bookings.lease_information.occupant_rental *
														+bookings.lease_information.occupancy_lease_term.split(' ')[0] *
														0.035
													).toFixed(0),
													propertyDictionary[bookings.booking_information?.property_uuid]?.currency
														.currency_symbol,
												)}
											</DefaultText>
										</>
									)}
								</>
							)}
						</PanelCard>
					)}

					{inboxType === InboxType.Landlord && bookings.personal_information && (
						<>
							{
								<PanelCard>
									<Row style={{ justifyContent: 'space-between' }}>
										<Row style={{ gap: 8 }}>
											<PersonSvg />
											<DefaultText>
												<b>Personal information</b>
											</DefaultText>
										</Row>
										{showPersonal ? (
											<MinusSvg
												style={{ cursor: 'pointer' }}
												onClick={() => setShowPersonal(prev => !prev)}
											/>
										) : (
											<PlusSvg
												style={{ cursor: 'pointer' }}
												onClick={() => setShowPersonal(prev => !prev)}
											/>
										)}
									</Row>

									{showPersonal && bookings.personal_information && (
										<>
											<DefaultText style={{ marginTop: 16 }}>Tenant type</DefaultText>
											<DefaultText large>
												{bookings.personal_information.occupant_type || '-'}
											</DefaultText>

											<DefaultText style={{ marginTop: 16 }}>Birth date</DefaultText>
											<DefaultText large>
												{bookings.personal_information.birth_date || '-'}
											</DefaultText>

											<DefaultText style={{ marginTop: 16 }}>Gender</DefaultText>
											<DefaultText large>{bookings.personal_information.gender || '-'}</DefaultText>

											<DefaultText style={{ marginTop: 16 }}>Age</DefaultText>
											<DefaultText large>{bookings.personal_information.age || '-'}</DefaultText>

											<DefaultText style={{ marginTop: 16 }}>Nationality</DefaultText>
											<DefaultText large>
												{bookings.personal_information.nationality || '-'}
											</DefaultText>
										</>
									)}
								</PanelCard>
							}

							{
								<>
									{bookings.personal_information.occupant_type === 'Student' ? (
										<PanelCard>
											<Row style={{ justifyContent: 'space-between' }}>
												<Row style={{ gap: 8 }}>
													<StudySvg />
													<DefaultText>
														<b>Educational information</b>
													</DefaultText>
												</Row>
												{showEducational ? (
													<MinusSvg
														style={{ cursor: 'pointer' }}
														onClick={() => setShowEducational(prev => !prev)}
													/>
												) : (
													<PlusSvg
														style={{ cursor: 'pointer' }}
														onClick={() => setShowEducational(prev => !prev)}
													/>
												)}
											</Row>

											{showEducational && bookings.personal_information && (
												<>
													<DefaultText style={{ marginTop: 16 }}>Institution</DefaultText>
													<DefaultText large>
														{bookings.personal_information.institution.name}
													</DefaultText>

													<DefaultText style={{ marginTop: 16 }}>Level of study</DefaultText>
													<DefaultText large>
														{bookings.personal_information.level_of_study}
													</DefaultText>
												</>
											)}
										</PanelCard>
									) : (
										<PanelCard>
											<Row style={{ justifyContent: 'space-between' }}>
												<Row style={{ gap: 8 }}>
													<OccupationSvg />
													<DefaultText>
														<b>Occupation information</b>
													</DefaultText>
												</Row>
												{showOccupation ? (
													<MinusSvg
														style={{ cursor: 'pointer' }}
														onClick={() => setShowOccupation(prev => !prev)}
													/>
												) : (
													<PlusSvg
														style={{ cursor: 'pointer' }}
														onClick={() => setShowOccupation(prev => !prev)}
													/>
												)}
											</Row>

											{showOccupation && bookings.personal_information && (
												<>
													<DefaultText style={{ marginTop: 16 }}>Institution</DefaultText>
													<DefaultText large>
														{bookings.personal_information.occupation}
													</DefaultText>
												</>
											)}
										</PanelCard>
									)}
								</>
							}
						</>
					)}
				</>
			)}
			{activeTab === Tab.Attachments &&
				attachments &&
				(bookings?.status === ConversationStatus.Expired ||
				bookings?.status === ConversationStatus.Cancelled ? (
					<DefaultText
						style={{
							textAlign: 'center',
							paddingTop: 24,
							paddingLeft: '16px',
							paddingRight: '16px',
						}}
					>
						Attachments not available in expired or cancelled conversations
					</DefaultText>
				) : (
					<AttachmentsContainer>
						<AttachmentsHeaderText>Images</AttachmentsHeaderText>
						{attachments.images && Boolean(attachments.images.length) ? (
							<AttachmentsList>
								{attachments.images.map(image => (
									<AttachmentContainer key={image.file}>
										<AttachmentImage src={image.file} />
									</AttachmentContainer>
								))}
							</AttachmentsList>
						) : (
							<EmptyStateText>No images added</EmptyStateText>
						)}
						<Divider />
						<AttachmentsHeaderText>Documents</AttachmentsHeaderText>
						{attachments.documents && Boolean(attachments.documents.length) ? (
							<AttachmentsList>
								{[...attachments.documents].map(document => (
									<DocumentAttachmentContainer key={document.file}>
										<DocumentImage>
											<DocumentLink href={document.file} target="_blank">
												<DocumentSvg style={{ width: 32 }} />
											</DocumentLink>
										</DocumentImage>
										<DocumentNameText>{getFileName(document.file || '')}</DocumentNameText>
									</DocumentAttachmentContainer>
								))}
							</AttachmentsList>
						) : (
							<EmptyStateText>No documents added</EmptyStateText>
						)}
					</AttachmentsContainer>
				))}
		</>
	);
};

const Row = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

const HeaderBar = styled.div`
	width: 100%;
	display: flex;
	height: 64px;
`;

const HeaderItem = styled.div<{ active?: boolean }>`
	border-bottom: 4px solid ${colors.grey10};
	flex: 1;
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 600;
	color: ${({ active }) => (active ? colors.darkBlue : colors.grey20)};
	border-bottom-color: ${({ active }) => (active ? colors.darkBlue : colors.grey10)};
	font-size: 14px;
	cursor: pointer;
	min-height: 56px;
`;

const DocumentsHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 24px;
`;

const ProfilePicture = styled.img`
	border-radius: 50%;
	width: 40px;
	height: 40px;
`;

const NameDisplay = styled.div`
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 8px;
`;

const StatusDisplay = styled.div`
	font-size: 14px;
	font-weight: 600;
`;

const PanelCard = styled.div`
	box-shadow: 0px 6px 20px 0px #0000001a;
	margin: 16px;
	border-radius: 16px;
	padding: 16px;
	padding-top: 24px;
`;

const SpacedRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

const DefaultText = styled.div<{ large?: boolean }>`
	font-size: ${({ large }) => (large ? 16 : 14)}px;
	margin: 4px 0;
	${({ large }) => (large ? 'margin-top: 8px;' : '')};
`;

const AttachmentsHeaderText = styled(DefaultText)`
	font-weight: 600;
	font-size: 16px;
`;

const AttachmentsContainer = styled.div`
	margin: 16px;
`;

const EmptyStateText = styled(DefaultText)`
	color: ${colors.grey60};
	text-align: center;
	margin-top: 16px;
`;

const AttachmentsList = styled.div`
	display: flex;
	margin-top: 16px;
	flex-wrap: wrap;
	margin-right: -16px;
`;

const AttachmentContainer = styled.div`
	width: 33%;
	height: 100px;
	padding-right: 16px;
	margin-bottom: 16px;
`;

const DocumentAttachmentContainer = styled(AttachmentContainer)`
	height: auto;
	min-height: 100px;
`;

const AttachmentImage = styled.img`
	height: 100px;
	width: 100%;
	object-fit: cover;
	border-radius: 8px;
	background: ${colors.grey10};
`;

const DocumentImage = styled.div`
	height: 100px;
	width: 100%;
	background: ${colors.grey10};
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	margin-bottom: 8px;
	cursor: pointer;
`;

const DocumentLink = styled.a`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const DocumentNameText = styled.div`
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

const ButtonContentContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
`;

const ButtonText = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
`;

const DiscountNotice = styled.div`
	background: ${colors.brightBlue};
	color: ${colors.white};
	position: absolute;
	min-width: 240px;
	max-width: 100%;
	font-size: 14px;
	line-height: 18px;
	width: 100%;
	font-weight: 600;
	padding: 16px;
	border-radius: 16px;
	z-index: 100;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	bottom: 68px;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		max-width: 100vw;
		left: 0;
		right: 0;
	}
`;

const TourPointer = styled(PointerTriangleSvg)`
	position: absolute;
	z-index: 100;
	display: flex;
	justify-content: center;
	width: 100%;
	left: 0;
	bottom: -10px;
	transform: rotate(180deg);
`;

export default RightPanel;
