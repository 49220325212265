import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import {
	Conversation,
	ConversationMessage,
	ConversationMessages,
	ConversationStatus,
	ConversationUser,
	MessageType,
} from 'models/inbox';
import { ReactComponent as AttachSvg } from 'assets/icons/attach.svg';
import { ReactComponent as InfoOutlinedSvg } from 'assets/icons/info-outlined.svg';
import { ReactComponent as HelpOutlinedSvg } from 'assets/icons/question-outlined.svg';
import { ReactComponent as ArrowRightSvg } from 'assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeftSvg } from 'assets/icons/arrow-left.svg';
import { ReactComponent as TickSvg } from 'assets/icons/tick.svg';
import { ReactComponent as DocumentSvg } from 'assets/icons/document.svg';

import theme from 'config/theme';
import ThreeDotsLoader from 'lib/ThreeDotsLoader';
import dateService from 'services/dateService';
import { useScreenWidth } from 'hooks/useScreenWidth';
import { BREAKPOINT_TABLET_LARGE_PX, BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import { InboxType } from './types';
import MessageInput from './MessageInput';

const { colors } = theme;

interface ConversationMessageDisplay extends ConversationMessage {
	isDateContext?: boolean;
}

const parseAsMarkup = (message: string): string => {
	return message.replaceAll('\n', '<br>');
};

const insertDateItems = (messages: ConversationMessage[]): ConversationMessageDisplay[] => {
	const results: ConversationMessageDisplay[] = [];
	const messagesList = [...messages].reverse();
	for (let i = 0; i < messagesList.length; i++) {
		const message = messagesList[i];
		const lastMessage = i !== 0 ? messagesList[i - 1] : null;
		if (
			!lastMessage ||
			!dateService.isSameDay(new Date(message.date_created), new Date(lastMessage.date_created))
		) {
			results.push({
				...message,
				uuid: message.uuid + new Date().toISOString(),
				message_type: MessageType.Unknown,
				isDateContext: true,
			});
		}
		results.push(message);
	}
	return results.reverse();
};

interface ChatPanelProps {
	myId: string;
	myInboxType: InboxType;
	chat: Conversation;
	chatMessages?: ConversationMessages;
	isLoading: boolean;
	isLoadingNextPage: boolean;
	isExpanded: boolean;
	contextMenuElement: any;
	onHelpClick(): void;
	onReportClick(): void;
	onExpandToggle(): void;
	onSendMessage(message: string): void;
	onFileUpload(file: File): void;
	onNextPageRequest(): void;
}

const ChatPanel = ({
	myId,
	myInboxType,
	chat,
	chatMessages,
	isExpanded,
	isLoading,
	isLoadingNextPage,
	contextMenuElement,
	onHelpClick,
	onReportClick,
	onExpandToggle,
	onSendMessage,
	onNextPageRequest,
	onFileUpload,
}: ChatPanelProps) => {
	const [message, setMessage] = useState('');
	const screenWidth = useScreenWidth();
	const messagesContainerRef = useRef<HTMLDivElement>(null);
	const [showMobileNav, setShowMobileNav] = useState(true);
	const fileInputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		const handleScroll = () => {
			if (isLoadingNextPage) {
				return;
			}
			const scrollableDiv = messagesContainerRef.current;
			if (scrollableDiv) {
				const { scrollTop, scrollHeight, clientHeight } = scrollableDiv;
				const distanceToTop = scrollHeight - clientHeight + scrollTop;
				if (distanceToTop < 100) {
					onNextPageRequest();
				}
			}
		};

		const scrollableDiv = messagesContainerRef.current;

		if (!scrollableDiv) {
			return;
		}

		scrollableDiv.addEventListener('scroll', handleScroll);

		return () => {
			scrollableDiv.removeEventListener('scroll', handleScroll);
		};
	}, [messagesContainerRef.current, isLoadingNextPage, chatMessages]);

	const getUserDisplayText = (user: ConversationUser): string => {
		if (user.uuid === myId) {
			return `Me, the ${myInboxType === InboxType.Tenant ? 'tenant' : 'landlord'}`;
		}

		if (user.uuid) {
			return `${user.first_name}, the ${myInboxType === InboxType.Tenant ? 'landlord' : 'tenant'}`;
		}

		return `${user.first_name}, the booking facilitator`;
	};

	const handlePostMessage = async () => {
		if (!message) {
			return;
		}

		onSendMessage(message);
		setMessage('');
	};

	const handleFileChange = async (event: any) => {
		const file = event?.target.files[0];
		onFileUpload(file);
	};

	const handleFileUploadClick = () => {
		fileInputRef.current?.click();
	};

	const getFileName = (fileUrl: string) => {
		const parts = fileUrl.split('/');
		const part = parts[parts.length - 1];
		return part.split('?')[0];
	};

	return (
		<Container noPadding={!showMobileNav}>
			{showMobileNav && contextMenuElement}
			{screenWidth > BREAKPOINT_TABLET_PX && (
				<HeaderBar>
					<ProfilePictures>
						{chat.users.map(user => (
							<PopupTrigger key={user.uuid}>
								<HeaderProfilePictureIcon src={user.profile_picture.url} />
								<InfoPopup>{getUserDisplayText(user)}</InfoPopup>
							</PopupTrigger>
						))}
					</ProfilePictures>
					<HeaderText>
						{chat.users
							.slice(0, chat.users.length - 1)
							.map(user => user.first_name)
							.join(', ') + ` & ${chat.users[chat.users.length - 1].first_name}`}
						<PopupContainer>
							<HelpOutlinedSvg
								height={24}
								width={24}
								style={{ cursor: 'pointer', marginLeft: 8 }}
								onClick={onHelpClick}
							/>
							<Popup>Help</Popup>
						</PopupContainer>
						<PopupContainer>
							<InfoOutlinedSvg
								style={{ cursor: 'pointer', marginLeft: 8 }}
								onClick={onReportClick}
							/>
							<Popup>Report</Popup>
						</PopupContainer>
						{screenWidth > BREAKPOINT_TABLET_LARGE_PX && (
							<ExpandCollapseButton onClick={onExpandToggle}>
								{isExpanded ? (
									<ArrowLeftSvg style={{ fill: colors.white, width: 16 }} />
								) : (
									<ArrowRightSvg style={{ fill: colors.white, width: 16 }} />
								)}
							</ExpandCollapseButton>
						)}
					</HeaderText>
				</HeaderBar>
			)}
			<ContentContainer ref={messagesContainerRef}>
				{isLoading ? (
					<LoaderContainer>
						<ThreeDotsLoader />
					</LoaderContainer>
				) : (
					!!chatMessages && (
						<>
							{insertDateItems(chatMessages.messages.results).map(message => (
								<div key={message.uuid}>
									{message.isDateContext && (
										<DateMessageContainer>
											<BoldMessageInfoText>
												{dateService.formatDateLongDisplay(new Date(message.date_created))}
											</BoldMessageInfoText>
										</DateMessageContainer>
									)}
									{message.message_type === MessageType.Context && (
										<ContextMessage>
											<ContextMessageRow>
												{message.extra_data.icon_url && (
													<ContextIcon src={message.extra_data.icon_url} />
												)}
												<BoldMessageInfoText>
													<p>
														{message.message}
														<span style={{ fontWeight: 400, whiteSpace: 'nowrap' }}>
															{!message.extra_data.inline_action &&
																` • ${dateService.formatTimeDisplay(
																	new Date(message.date_created),
																)}`}
														</span>
														{message.extra_data.context_text &&
															message.extra_data.context_action &&
															message.extra_data.inline_action && (
																<ContextAction
																	href={message.extra_data.context_action}
																	target="_blank"
																	style={{
																		marginLeft: 8,
																		color: colors.grey60,
																		textDecoration: 'underline',
																	}}
																>
																	{message.extra_data.context_text}
																</ContextAction>
															)}
													</p>
												</BoldMessageInfoText>
											</ContextMessageRow>
											{message.extra_data.context_text &&
												message.extra_data.context_action &&
												!message.extra_data.inline_action && (
													<ContextAction href={message.extra_data.context_action} target="_blank">
														{message.extra_data.context_text}
													</ContextAction>
												)}
										</ContextMessage>
									)}
									{(message.message_type === MessageType.Message ||
										message.message_type === MessageType.Support) && (
										<Message
											key={message.date_created_epoch}
											sentByMe={message.user?.uuid === myId}
										>
											<Row>
												{!!message.user && (
													<MessageBoldText>{message.user?.first_name}</MessageBoldText>
												)}
												<MessageInfoText>
													{dateService.formatTimeDisplay(new Date(message.date_created))}
												</MessageInfoText>
												{message.read && message.user?.uuid === myId && (
													<BoldMessageInfoText>
														<TickSvg style={{ marginRight: 4 }} />
														Read
													</BoldMessageInfoText>
												)}
											</Row>
											<MessageText
												dangerouslySetInnerHTML={{ __html: parseAsMarkup(message.message) }}
											></MessageText>
										</Message>
									)}
									{message.message_type === MessageType.Image && (
										<Message
											key={message.date_created_epoch}
											sentByMe={message.user?.uuid === myId}
										>
											<Row>
												{!!message.user && (
													<MessageBoldText>{message.user?.first_name}</MessageBoldText>
												)}
												<MessageInfoText>
													{dateService.formatTimeDisplay(new Date(message.date_created))}
												</MessageInfoText>
												{message.read && message.user?.uuid === myId && (
													<BoldMessageInfoText>
														<TickSvg style={{ marginRight: 4 }} />
														Read
													</BoldMessageInfoText>
												)}
											</Row>
											<MessageImageContainer>
												{message.file ? (
													<MessageImage src={message.file} />
												) : (
													<>{message.message}</>
												)}
											</MessageImageContainer>
										</Message>
									)}
									{message.message_type === MessageType.Document && (
										<Message
											key={message.date_created_epoch}
											sentByMe={message.user?.uuid === myId}
										>
											<Row>
												{!!message.user && (
													<MessageBoldText>{message.user?.first_name}</MessageBoldText>
												)}
												<MessageInfoText>
													{dateService.formatTimeDisplay(new Date(message.date_created))}
												</MessageInfoText>
												{message.read && message.user?.uuid === myId && (
													<BoldMessageInfoText>
														<TickSvg style={{ marginRight: 4 }} />
														Read
													</BoldMessageInfoText>
												)}
											</Row>
											{message.file ? (
												<DocumentMessageTextContainer>
													<Row>
														<DocumentSvg style={{ height: 40, marginRight: 4 }} />
														<MessageBoldText>{getFileName(message.file || '')}</MessageBoldText>
													</Row>
													<ContextAction href={message.file} target="_blank">
														Download
													</ContextAction>
												</DocumentMessageTextContainer>
											) : (
												<DocumentMessageTextContainer>
													<MessageText>{message.message}</MessageText>
												</DocumentMessageTextContainer>
											)}
										</Message>
									)}
								</div>
							))}
							{isLoadingNextPage && (
								<MessagesNextPageLoader>
									<ThreeDotsLoader />
								</MessagesNextPageLoader>
							)}
						</>
					)
				)}
			</ContentContainer>
			<InputBar>
				<MessageInput
					isLocked={
						chat.status === ConversationStatus.Cancelled ||
						chat.status === ConversationStatus.Expired
					}
					onFocus={() => setShowMobileNav(false)}
					onBlur={() => setShowMobileNav(true)}
					message={message}
					onChange={setMessage}
					onSubmit={handlePostMessage}
				/>
				{!(
					chat.status === ConversationStatus.Cancelled || chat.status === ConversationStatus.Expired
				) && (
					<AttachSvg
						onClick={handleFileUploadClick}
						style={{ marginLeft: 16, cursor: 'pointer' }}
					/>
				)}
			</InputBar>
			<input
				ref={fileInputRef}
				onChange={handleFileChange}
				type="file"
				hidden
				style={{ display: 'none' }}
			/>
		</Container>
	);
};

const Container = styled.div<{ noPadding?: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding-top: ${({ noPadding }) => (noPadding ? 0 : 98)}px;
	position: relative;

	@media (min-width: ${BREAKPOINT_TABLET_PX}px) {
		padding-top: 0;
	}
`;

const MessagesNextPageLoader = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;
`;

const HeaderBar = styled.div`
	padding: 0 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 64px;
`;

const HeaderText = styled.div`
	font-weight: 600;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const ContentContainer = styled.div`
	background: ${colors.grey01};
	flex: 1;
	width: 100%;
	padding: 0 16px;
	display: flex;
	flex-direction: column-reverse;
	overflow-y: auto;
	padding-top: 16px;

	::-webkit-scrollbar-thumb {
		background: transparent;
	}

	:hover {
		::-webkit-scrollbar-thumb {
			background: ${colors.scrollbar};
		}
	}

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		padding-bottom: 80px;
	}
`;

const InputBar = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	padding-bottom: 16px;
	width: 100%;
	border-top: 1px solid ${colors.grey20};

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		position: fixed;
		left: 0;
		bottom: 0;
		background: ${colors.white};
	}
`;

const ProfilePictureIcon = styled.img`
	min-width: 40px;
	width: 40px;
	height: 40px;
	border-radius: 40px;
`;

const HeaderProfilePictureIcon = styled(ProfilePictureIcon)`
	border: 2px solid ${colors.white};
	border-radius: 40px;
	margin-right: -8px;
	background: ${colors.grey05};
`;

const ProfilePictures = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

const LoaderContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
`;

const ExpandCollapseButton = styled.div`
	background: ${colors.darkBlue};
	display: flex;
	justify-content: center;
	align-items: center;
	height: 64px;
	width: 24px;
	margin-right: -16px;
	margin-left: 16px;
	cursor: pointer;
`;

const Message = styled.div<{ sentByMe?: boolean }>`
	padding: 8px 16px;
	margin-bottom: 12px;
	border-radius: ${({ sentByMe }) => (sentByMe ? `16px 16px 0 16px` : `16px 16px 16px 0`)};
	background: ${({ sentByMe }) => (sentByMe ? colors.darkTurquoiseFaded : colors.white)};
	box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
	width: 100%;
`;

const ContextMessage = styled(Message)`
	border-radius: 50px;
	background: transparent;
	border: 1px solid ${colors.grey20};
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow: none;
`;

const ContextMessageRow = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

const ContextIcon = styled.img`
	min-width: 24px;
	width: 24px;
	height: 24px;
	display: flex;
	margin-right: 12px;
	align-items: center;
	justify-content: center;
	object-fit: contain;
`;

const ContextAction = styled.a`
	text-decoration: none;
	color: ${colors.darkTurquoise};
	font-weight: 600;
	font-size: 14px;
	cursor: pointer;
	white-space: nowrap;
`;

const MessageText = styled.div`
	width: 100%;
	max-width: 100%;
	overflow: hidden;
	display: block;
	text-overflow: ellipsis;
	font-size: 16px;
	line-height: 150%;
`;

const MessageBoldText = styled(MessageText)`
	font-weight: 600;
`;

const MessageInfoText = styled.div`
	color: ${colors.grey60};
	font-size: 12px;
	line-height: 150%;
`;

const BoldMessageInfoText = styled(MessageInfoText)`
	font-weight: 600;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Row = styled.div`
	margin-bottom: 4px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 8px;
`;

const DocumentMessageTextContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const InfoPopup = styled.div`
	position: absolute;
	padding: 8px 16px;
	border-radius: 12px;
	background: ${colors.white};
	left: 0;
	top: 44px;
	font-size: 12px;
	font-weight: 400;
	cursor: pointer;
	color: ${colors.darkBlue};
	line-height: 150%;
	z-index: 1;
	width: fit-content;
	white-space: nowrap;
	visibility: hidden;
	box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
`;

const PopupTrigger = styled.div`
	position: relative;
	width: fit-content;
	cursor: pointer;

	&:hover ${InfoPopup} {
		visibility: visible;
	}
`;

const DateMessageContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 8px;
	margin-bottom: 16px;
`;

const MessageImage = styled.img`
	object-fit: contain;
	width: 100%;
	height: 100%;
`;

const MessageImageContainer = styled.div`
	border-radius: 8px;
	width: 100%;
	height: 180px;
	background: ${colors.grey05};
	display: flex;
	align-items: center;
	justify-content: center;

	@media (min-width: ${BREAKPOINT_TABLET_PX}px) {
		width: 240px;
	}
`;

const Popup = styled.div`
	position: fixed;
	padding: 12px;
	border-radius: 8px;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
	background: ${colors.white};
	z-index: 100;
	visibility: hidden;
	font-size: 12px;
	font-weight: 400;
`;

const PopupContainer = styled.div`
	position: relative;

	&:hover ${Popup} {
		visibility: visible;
	}
`;

export default ChatPanel;
