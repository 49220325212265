import React, { Component } from 'react';

import 'unfetch/polyfill';
import { Route, Router, Redirect, Switch } from 'react-router-dom';
import BasicErrorBoundary from 'react-basic-error-boundary';
import { Helmet } from 'react-helmet';
import { createBrowserHistory } from 'history';
import { Provider } from 'unstated';
import styled from 'styled-components';

import api from 'api';
import authContainer from 'containers/auth';
import userContainer from 'containers/user';
import routes from 'config/routes';
import loadable from './loadable';
import { GlobalStyle } from './styles';
import { withLandlordRedirect } from './withLandlordRedirect';
import { Loader } from 'lib/Loader';
import theme from 'config/theme';
import { analyticsService, EventName } from 'services/analyticsService';
import { userProfileService } from 'services/userProfileService';
import modalContainer from 'containers/modal';
import { ModalType } from 'constants/modalTypes';
import { isProd, isStaging, featureFlagContainer, FeatureFlag } from 'containers/featureFlags';
import ModalEnum from 'models/modalEnum';
import initializeSentry from 'utils/initializeSentry';
import { Subscribe } from 'unstated';
import Inbox from 'components/inbox/Inbox';
import LoginModal from 'components/modals/login';
import CancelEnquiryModal from 'components/modals/cancel-enquiry';
import NoInstitutionFoundModal from 'components/modals/no-institution-found';
import EnquiryInfoModal from 'components/modals/enquiry-info';
import TermsAndConditionsModal from 'components/modals/terms-and-conditions';
import ForgotPasswordModal from 'components/modals/forgot-password';
import UserProfileSetup from 'components/user-profile/user-profile-setup/UserProfileSetup';
import ProductUpdateModal from 'components/modals/ProductUpdateModal';
import LandlordLandingTestimonyModal from 'components/modals/LandlordLandingTestimonyModal';
import ReportListingModal from 'components/modals/ReportListingModal';
import ContactUsModal from 'components/modals/ContactUsModal';
import InvoicesProductTourModalContent from 'components/dashboards/manage-listing-dashboard/InvoicesProductTourModalContent';
import { Modal } from 'lib/Modal';
import TrustedLandlordModal from 'components/modals/dashboard/TrustedLandlordModal';
import InfoPopupModal from 'components/modals/info-popup/InfoPopupModal';
import LandlordLandingProductUpdateModal from 'components/dashboards/landlord-dashboard/LandlordLandingProductUpdateModal';
import { AllFiltersModal } from 'components/digs-search-v2/filters/AllFilters';

const { colors } = theme;

const Landing = loadable(() => import('components/landing'));
const LandingLandlord = loadable(() => import('components/landing/LandlordLanding'));
const Safety = loadable(() => import('components/static-pages/Safety'));
const Faqs = loadable(() => import('components/faqs/Faqs'));
const ContactUs = loadable(() => import('components/contact-us'));
const DigListing = loadable(() => import('components/digs-listing'));
const Pricing = loadable(() => import('components/pricing/Pricing'));
const VerificationsDisclaimer = loadable(() => import('components/VerificationsDisclaimer'));
const DigsSearch = loadable(() => import('components/digs-search-v2/DigsSearch'));
const PartnersSearch = loadable(() => import('components/partners/PartnersSearch'));
const NotFound = loadable(() => import('components/response-pages/NotFound'));
const Applications = loadable(() => import('components/applications'));
const ApplicationsDetail = loadable(() => import('components/applications-detail'));
const Payment = loadable(() => import('components/payment'));
const FavouritesDashboard = loadable(() =>
	import('components/dashboards/favourites-dashboard/FavouritesDashboard'),
);
const ManageListingMain = loadable(() =>
	import('components/dashboards/manage-listing-dashboard/ManageListingMain'),
);
const Booking = loadable(() => import('components/booking-v3/Booking'));
const Privacy = loadable(() => import('components/static-pages/PrivacyPolicy'));
const AboutUs = loadable(() => import('components/static-pages/AboutUs'));
const HowItWorks = loadable(() => import('components/static-pages/HowItWorks'));
const LandlordResources = loadable(() => import('components/static-pages/LandlordResources'));
const Partners = loadable(() => import('components/static-pages/Partners'));
const TermsAndConditions = loadable(() => import('components/static-pages/TermsAndConditions'));
const AddDigs = loadable(() => import('components/add-digs/AddDigs'));
const EditDigs = loadable(() => import('components/edit-digs/EditDigs'));
const Account = loadable(() => import('components/account/Account'));
const AccountProfile = loadable(() => import('components/account/profile/ManageProfile'));
const AccountNotifications = loadable(() => import('components/account/ManageNotifications'));

const history = createBrowserHistory();
// HACK: Go to top of page on navigate
history.listen(() => {
	window.scrollTo(0, 0);
});

// Intialize Sentry
if (isProd()) {
	initializeSentry('production');
}
if (isStaging()) {
	initializeSentry('staging');
}

class App extends Component {
	state = {
		isInitialised: false,
	};

	async componentDidMount() {
		const query = new URLSearchParams(history.location.search);
		if (query.get('otl') && !authContainer.state.token) {
			const oneTimeLoginResponse = await api.auth.oneTimeLogin({
				otl: query.get('otl'),
			});
			if (oneTimeLoginResponse.data.token) {
				await authContainer.set({
					token: oneTimeLoginResponse.data.token,
				});

				const userProfileV2Response = await api.userV2.getProfile();
				await userContainer.set(userProfileV2Response.data);
			}
			history.push(history.location.pathname);
		}

		// NOTE: Refresh token on app load
		try {
			await this.initialise();

			// Give user identity to Hubspot
			// might be insecure to do this, users can identify themselves as whatever they want by changing this email value.
			// better to use an id locked behind auth instead
			// https://legacydocs.hubspot.com/docs/methods/tracking_code_api/identify_visitor
			if (userContainer.state.v2.first_name) {
				//Identity for hubspot
				var _hsq = (window._hsq = window._hsq || []);
				_hsq.push([
					'identify',
					{
						email: userContainer.state.v2.email && userContainer.state.v2.email.email,
					},
				]);
			}
		} catch (error) {
			// NOTE: we clear out the token and user if expired
			await authContainer.clear();
			await userContainer.clear();
		}
	}

	componentWillUnmount() {
		this.timeout && clearTimeout(this.timeout);
	}

	async initialise() {
		const lastRefresh = Date.now();
		const refreshTokenResponse = await api.auth.refreshToken();

		if (!refreshTokenResponse || refreshTokenResponse.status !== 200) {
			await userContainer.clear();
			await authContainer.clear();
			analyticsService.trackEvent(EventName.SetUser, { digs_user_id: '' });
			this.setState({
				isInitialised: true,
			});
			return;
		}

		await authContainer.set({
			token: !!refreshTokenResponse.data.token,
			lastRefresh,
		});

		const userProfileV2Response = await api.userV2.getProfile();
		await userContainer.set(userProfileV2Response.data);
		this.setState({
			isInitialised: true,
		});

		analyticsService.trackEvent(EventName.SetUser, {
			digs_user_id: userProfileV2Response.data.uuid,
		});

		if (!userProfileService.isComplete(userProfileV2Response.data)) {
			modalContainer.set(ModalType.ProfileSetup);
			return;
		}
		if (
			featureFlagContainer.isEnabled(FeatureFlag.LandlordDashboard) &&
			!userProfileV2Response.data.has_viewed_dashboard_modal
		) {
			modalContainer.set(ModalType.LandlordLandingProductUpdate);
			return;
		}

		if (!userProfileV2Response.data.has_viewed_edit_listing_tour_modal) {
			api.property
				.getProperties()
				.then(res => res.data)
				.then(listings => {
					if (!listings || !listings.length) {
						return;
					}
					modalContainer.set(ModalEnum.ProductUpdate);
				});
		}
	}

	render() {
		if (!this.state.isInitialised) {
			return (
				<LoaderContainer>
					<Loader />
				</LoaderContainer>
			);
		}

		return (
			<Provider>
				<Router history={history}>
					<GlobalStyle />
					<Helmet>
						<title itemProp="name" lang="en">
							Digsconnect.com: Student Accommodation in South Africa
						</title>
						<meta
							name="description"
							content="Africa's largest Student Accommodation platform. DigsConnect.com connects students with accommodation providers and with other students looking for accommodation."
						/>
					</Helmet>
					<>
						<BasicErrorBoundary
							fallback={() => <NotFound />}
							onError={error => console.error(error)}
						>
							<Switch>
								{/* Static */}
								<Route path={routes.inbox_route_definition} exact component={Inbox} />
								<Route path={routes.home} exact component={Landing} />
								<Route path={routes.landlordLanding} exact component={LandingLandlord} />
								<Route path={routes.faqs} exact component={Faqs} />
								<Route path={routes.safety} component={Safety} />
								<Route path={routes.contact_us} component={ContactUs} />
								<Route path={routes.about} component={AboutUs} />
								<Route path={routes.how_it_works} component={HowItWorks} />
								<Route path={routes.landlord_resources} component={LandlordResources} />
								<Route path={routes.partners} component={Partners} />
								<Route path={routes.terms_and_conditions} component={TermsAndConditions} />
								<Route path={routes.privacy} component={Privacy} />
								<Route path={routes.verificationsDisclaimer} component={VerificationsDisclaimer} />
								<Route path={routes.account_dashboard} component={Account} />
								<Route path={routes.account_profile} component={AccountProfile} />
								<Route path={routes.account_notifications} component={AccountNotifications} />
								<Route path={routes.profile}>
									<Redirect to={routes.account_dashboard} />
								</Route>
								<Route path={routes.account}>
									<Redirect to={routes.account_dashboard} />
								</Route>
								<Route
									path={routes.manage_alerts}
									component={withLandlordRedirect(null, routes.manage_alerts)}
								/>
								<Route path={routes.favourites} component={FavouritesDashboard} />
								<Route path={routes.manage_listing} component={ManageListingMain} />
								<Route path={routes.pricing} component={Pricing} />
								{/* Remove below route once chat is fully integrated */}
								<Route path={routes.messages}>
									<Redirect to={routes.inbox} />
								</Route>
								{/* Remove below route once chat is fully integrated */}
								<Route path={routes.my_bookings}>
									<Redirect to={routes.inbox} />
								</Route>
								{/* Landlords */}
								<Route
									path={routes.listings}
									component={withLandlordRedirect(null, routes.listings)}
								/>
								<Route
									path={routes.listing_edit}
									component={withLandlordRedirect(null, routes.listings)}
								/>
								<Route
									path={routes.manage_digs}
									component={withLandlordRedirect(null, routes.manage_digs)}
								/>
								<Route path={routes.digs_search_legacy}>
									<Redirect to={routes.digs_search} />
								</Route>
								<Route path={routes.digs_search} component={DigsSearch} />
								<Route path={routes.partners_search} component={PartnersSearch} />
								<Route path={routes.digs_detail} component={DigListing} />
								<Route path={routes.digs_detail_preview} component={DigListing} />
								<Route path={routes.book} component={Booking} />
								<Route path={routes.applications} component={Applications} />
								<Route path={routes.applications_detail} component={ApplicationsDetail} />
								<Route path={routes.add_a_digs_legacy}>
									<Redirect to={routes.create_listing} />
								</Route>
								<Route path={routes.create_listing_edit} component={AddDigs} />
								<Route
									path={routes.edit_digs}
									component={EditDigs}
								/>
								<Route path={routes.payment} component={Payment} />
								<Route component={NotFound} />
							</Switch>

							<Subscribe to={[modalContainer, userContainer]}>
								{(container, userContainer) => (
									<>
										{(container.state.active === 'signup' ||
											container.state.active === ModalType.Login) && (
											<LoginModal
												onLogin={() => container.set(ModalType.Login)}
												onSignup={() => container.set('signup')}
												onClose={() => container.set('')}
												onForgotPassword={() => container.set('forgot-password')}
												onLoginSuccess={
													container.state.props && container.state.props.onLoginSuccess
												}
												isSignup={container.state.active === 'signup'}
											/>
										)}
										{container.state.active === 'forgot-password' && (
											<ForgotPasswordModal onClose={() => container.set('')} />
										)}
										{container.state.active === ModalType.InfoPopup && (
											<InfoPopupModal
												{...container.state.props}
												onClose={() => container.set('')}
											/>
										)}
										{container.state.active === ModalType.ContactUs && (
											<ContactUsModal onClose={() => container.set('')} />
										)}
										{container.state.active === ModalType.ReportListing && (
											<ReportListingModal onClose={() => container.set('')} />
										)}
										{container.state.active === ModalType.LandlordLandingTestimony && (
											<LandlordLandingTestimonyModal onClose={() => container.set('')} />
										)}
										{container.state.active === ModalType.LandlordLandingProductUpdate && (
											<LandlordLandingProductUpdateModal
												landlordName={userContainer?.state?.v2?.first_name}
												onClose={async () => {
													await api.userV2.updateProfile({
														has_viewed_dashboard_modal: true,
													});
													container.close();
												}}
											/>
										)}
										{container.state.active === ModalEnum.ProductUpdate && (
											<ProductUpdateModal
												userName={userContainer?.state?.v2?.first_name}
												onClose={async () => {
													await api.userV2.updateProfile({
														has_viewed_edit_listing_tour_modal: true,
													});
													container.close();
												}}
											/>
										)}
										{(container.state.active === ModalType.ProfileSetup ||
											container.state.active === ModalType.ProfileSetupInstant) && (
											<UserProfileSetup
												show={true}
												showImmediately={container.state.active === ModalType.ProfileSetupInstant}
												onClose={() => container.set('')}
												onComplete={() => {
													container.set('');
													if (container.state.props.onComplete) {
														container.state.props.onComplete();
													}
												}}
											/>
										)}
										{container.state.active === ModalType.MoreFilters && (
											<AllFiltersModal {...container.state.props} />
										)}
										{container.state.active === ModalType.CancelEnquiry && (
											<CancelEnquiryModal
												{...container.state.props}
												onClose={() => container.set('')}
											/>
										)}
										{container.state.active === ModalType.NoInstitutionFound && (
											<NoInstitutionFoundModal
												{...container.state.props}
												isOpen
												onClose={() => container.set('')}
											/>
										)}
										{container.state.active === ModalType.TrustedLandlord && (
											<TrustedLandlordModal
												{...container.state.props}
												onClose={() => container.set('')}
											/>
										)}
										{container.state.active === ModalType.enquiryInfo && (
											<EnquiryInfoModal
												{...container.state.props}
												onClose={() => container.set('')}
											/>
										)}
										{container.state.active === ModalType.termsAndConditions && (
											<TermsAndConditionsModal
												{...container.state.props}
												onClose={() => container.set('')}
											/>
										)}
										{container.state.active === ModalEnum.Generic && (
											<Modal
												width={container.state.props.width}
												onClose={() => container.close()}
												onBackgroundClick={() => container.close()}
											>
												{container.state.children}
											</Modal>
										)}
									</>
								)}
							</Subscribe>
						</BasicErrorBoundary>
					</>
				</Router>
			</Provider>
		);
	}
}

export default App;

const LoaderContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: ${colors.white};
	display: flex;
	align-items: center;
	justify-content: center;
`;
